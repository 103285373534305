@import "nprogress/nprogress";

#nprogress .bar {
  @apply h-0.5;
  @apply bg-gray-900;
}

/* Fancy blur effect */
#nprogress .peg {
  @apply shadow;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  @apply hidden;
}
