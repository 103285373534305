@import "tippy.js/dist/tippy.css";

.tippy-box[data-theme~="ycn"] {
  @apply bg-gray-900;
  @apply rounded-md;
  @apply text-base;
  @apply p-0;
}

.tippy-content {
  @apply p-0;
}

.tippy-arrow {
  @apply text-gray-900;
}
