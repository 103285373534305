@use "styles/settings";
@use "styles/media-queries";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

h1 {
  @include mixins.h1;
}

h2 {
  @include mixins.h1;
}

h3 {
  @include mixins.h3;
}

h4 {
  @include mixins.h4;
}

h5 {
  @include mixins.h5;
}
