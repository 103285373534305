@layer components {
  .link {
    @apply text-sky-500 underline decoration-sky-200 decoration-1 underline-offset-2 hover:text-sky-600 hover:decoration-sky-600/25;
  }

  .link-lg {
    @apply link;
    @apply underline-offset-4;
  }

  .tab-list {
    @apply inline-flex max-lg:w-full;
    @apply -space-x-2 rounded-lg bg-white p-1;
  }

  .tab-list-sm {
    @apply tab-list;
    @apply -space-x-1 rounded-md p-1 text-sm;
  }

  .tab {
    @apply flex max-lg:flex-1 items-center justify-center whitespace-nowrap rounded-lg text-gray-700 hover:text-black focus:outline-none focus:ring-0 ui-selected:bg-gray-100 ui-selected:text-black;
    @apply px-6 py-3 lg:px-6 lg:py-3;
  }

  .tab-list-sm .tab {
    @apply rounded-md px-4 py-2 font-normal;
  }

  .sidebar-navigation-items {
    @apply space-y-1 flex flex-col;
  }

  .sidebar-navigation-item {
    @apply leading-none w-full grow flex items-center space-x-4 h-12 rounded-lg px-4 hover:bg-white group-hover:text-gray-500 group-hover:hover:text-gray-900;
  }

  .sidebar-navigation-item--active {
    @apply bg-white text-gray-900;
  }

  .icon {
    @apply stroke-[1.5] h-5 w-5;
  }

  .ag-theme-quartz {
    --ag-font-size: theme("fontSize.base");
    --ag-font-family: theme("fontFamily.sans");
    --ag-header-background-color: theme("colors.zinc.50");
    --ag-row-border-color: theme("colors.zinc.100");
    --ag-header-cell-hover-background-color: theme("colors.sky.50");
  }
}
