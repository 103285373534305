@layer utilities {
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  .text-size-adjust {
    -webkit-text-size-adjust: 100%;
  }
  .optimize-legibility {
    text-rendering: optimizeLegibility;
  }

  .reset-button {
    text-align: left;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: currentColor;
    font: inherit;
    line-height: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
  }

  .heading-5xl {
    @apply font-bold;
    @apply text-6xl lg:text-7xl xl:text-8xl;
  }

  .heading-4xl {
    @apply font-bold;
    @apply text-5xl lg:text-6xl xl:text-7xl;
  }

  .heading-3xl {
    @apply font-bold;
    @apply text-4xl lg:text-5xl xl:text-6xl;
  }

  .heading-2xl {
    @apply font-medium;
    @apply text-3xl lg:text-4xl xl:text-5xl;
  }

  .heading-2xl--light {
    @apply heading-2xl;
    @apply font-light;
  }

  .heading-xl {
    @apply font-medium;
    @apply text-2xl lg:text-3xl xl:text-4xl;
  }

  .heading-xl--light {
    @apply heading-xl;
    @apply font-light;
  }

  .heading-lg {
    @apply font-medium;
    @apply text-xl lg:text-2xl xl:text-3xl;
  }

  .heading-lg--light {
    @apply heading-lg;
    @apply font-light;
  }

  .heading-md {
    @apply font-medium;
    @apply text-lg lg:text-xl xl:text-2xl;
  }

  .heading-md--light {
    @apply heading-md;
    @apply font-light;
  }

  .heading-sm {
    @apply font-medium;
    @apply text-base lg:text-lg xl:text-xl;
  }

  .heading-sm--light {
    @apply heading-sm;
    @apply font-light;
  }

  .heading-xs {
    @apply font-medium;
    @apply text-sm lg:text-base xl:text-lg;
  }

  .heading-xs--light {
    @apply heading-sm;
    @apply font-light;
  }

  .container {
    @apply mx-auto;
    @apply px-6 lg:px-16 2xl:px-20;
    @apply max-w-screen-2xl;
  }

  .hidden-scrollbar {
    &::-webkit-scrollbar-track {
      @apply invisible hidden;
    }

    &::-webkit-scrollbar {
      @apply invisible hidden;
    }

    &::-webkit-scrollbar-thumb {
      @apply invisible hidden;
    }
  }

  .pt-header {
    @apply pt-16 lg:pt-28;
  }

  .top-header {
    @apply top-16 lg:top-28;
  }
}
