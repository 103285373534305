@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "styles/media-queries";

.root {
  background-color: #fff;
  max-width: 720px;
  border-radius: settings.$gutter-width;
}

.header {
  background-image: url("/images/TURBO.png");
  background-repeat: no-repeat;
  background-position: center;

  @include media-queries.mq($until: tablet) {
    height: 150px;
    background-size: auto 100px;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    height: 150px;
    background-size: auto 100px;
  }

  @include media-queries.mq($from: desktop) {
    height: 150px;
    background-size: auto 120px;
  }
}

.content {
  padding-top: settings.$gutter-width * 2;
  padding-bottom: settings.$gutter-width * 4;
  padding-left: settings.$gutter-width * 1;
  padding-right: settings.$gutter-width * 1;
}

.panels {
  display: flex;

  @include media-queries.mq($until: tablet) {
    flex-direction: column;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    flex-direction: row;
  }

  @include media-queries.mq($from: desktop) {
    flex-direction: row;
  }
}

.panel {
  padding-left: settings.$gutter-width * 2;
  padding-right: settings.$gutter-width * 2;

  @include media-queries.mq($until: tablet) {
    margin-bottom: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    border-right: 1px solid settings.$color-border;
  }

  @include media-queries.mq($from: desktop) {
    border-right: 1px solid settings.$color-border;
  }

  &:last-child {
    border-right: 0;
    margin-bottom: 0;
  }
}

.caption {
  @include mixins.bodyText;
  margin-bottom: settings.$gutter-width;
  text-align: center;
}

.heading {
  margin-bottom: math.div(settings.$gutter-width, 2);
  text-align: center;
}

.action {
  display: block;
}
