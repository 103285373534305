/*
	Tailwind base
	*/

@import "tailwindcss/base";
@import "./custom-base-styles.css";

/*
	Tailwind components
	*/

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

@import "tailwindcss/components";
@import "./custom-components.css";

/*
	Tailwind utilities
	*/

@import "tailwindcss/utilities";
@import "./custom-utilities.css";

@import "lib/swiper";
@import "lib/tippy";
@import "lib/nprogress";
