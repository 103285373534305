@use "sass:math";

@use "styles/settings";
@use "styles/mixins";
@use "styles/media-queries";
@use "~awesome-sass-easing/sass-easing";

.root {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #fff;
  border-radius: settings.$gutter-width * 2;
  display: flex;
  align-items: center;

  @include mixins.hover-float;
  background-color: transparent;
}

.label {
  font-weight: 500;
  line-height: 1;
  margin-right: settings.$gutter-width;
  white-space: nowrap;
  font-size: 18px;
}

.icon {
  margin-left: settings.$gutter-width * 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0px);
  transition: transform 0.3s ease-out;
  margin-left: auto;

  @media (hover: hover) {
    .icon:hover & {
      transform: translateX(math.div(settings.$gutter-width, 4));
    }
  }
}
