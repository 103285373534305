@use "styles/media-queries";
@use "styles/settings";
@use "styles/mixins";
@use "~awesome-sass-easing/sass-easing";

.root {
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  backdrop-filter: blur(0px);
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    backdrop-filter 0.3s ease-out;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  justify-content: center;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
  }
}

.overlay___afterOpen {
  opacity: 1;
  backdrop-filter: blur(5px);
}

.overlay___beforeClose {
  opacity: 0;
}

.content {
  width: auto;
  transform: scale(0.92);
  outline: none;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
  }
}

.content___afterOpen {
  opacity: 1;
  transform: scale(1);
}

.content___beforeClose {
  opacity: 0;
  transform: scale(0.92);
}

.wrapper {
  position: relative;
  display: flex;

  @include media-queries.mq($until: tablet) {
    padding-top: settings.$gutter-width * 4;
    padding-bottom: settings.$gutter-width;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    padding-top: settings.$gutter-width * 2;
    padding-bottom: settings.$gutter-width * 2;
  }

  @include media-queries.mq($from: desktop) {
    padding: settings.$gutter-width * 3;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 1px solid transparent;
  }
}

.children {
  width: 100%;
}

.buttons {
  position: absolute;
  width: settings.$gutter-width * 3;

  @include media-queries.mq($until: tablet) {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
    top: settings.$gutter-width * 0;
    right: 0;
  }
}

.close {
  @include mixins.reset-button;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;

  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
  }
}

.pagination {
  @include media-queries.mq($until: tablet) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: settings.$gutter-width;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: settings.$gutter-width;
  }

  @include media-queries.mq($from: desktop) {
  }
}

.previousButton,
.nextButton {
  @include mixins.reset-button;
  color: #fff;

  @include media-queries.mq($until: tablet) {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-queries.mq($from: desktop) {
  }
}

.previousButton {
  @include media-queries.mq($until: tablet) {
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
  }

  @include media-queries.mq($from: desktop) {
    left: settings.$gutter-width * -1;
  }
}

.nextButton {
  @include media-queries.mq($until: tablet) {
    margin-left: auto;
  }

  @include media-queries.mq($from: tablet, $until: desktop) {
    margin-left: auto;
  }

  @include media-queries.mq($from: desktop) {
    right: settings.$gutter-width * -1;
  }
}
