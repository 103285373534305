@import "swiper/swiper-bundle.css";

.swiper-slide {
  @apply h-auto;
}

.swiper-button-prev,
.swiper-button-next {
  @apply hidden;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-pagination-bullet {
  @apply h-0.5;
  @apply w-8;
  @apply rounded-sm;
}

.swiper-pagination-bullet-active {
  @apply bg-gray-900;
}
